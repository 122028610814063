<template>
  <a-modal
    :visible="visible"
    :title="form.id ? '编辑' : '新增'"
    :mask-closable="false"
    :width="600"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
    forceRender
    :destroyOnClose="true"
  >
    <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="分类名称" v-bind="validateInfos.typeName">
        <a-input v-model:value="form.typeName" placeholder="请输入分类名称" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { reactive, toRaw, toRefs, watch } from 'vue';
import { Form } from 'ant-design-vue';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const required = { required: true, message: '不能为空' };
    const form = reactive({
      createTime: '',
      createUser: '',
      id: '',
      orgId: '',
      source: null,
      subType: '',
      tagStatic: false,
      tagTypeCode: '',
      type: '',
      // 分类名称
      typeName: '',
      typyDesc: '',
      updateTime: '',
      updateUser: '',
    });

    const state = reactive({
      treeData: [],
      category: {},
    });

    watch(
      () => props.init,
      init => Object.assign(form, init)
    );

    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      typeName: [required, { max: 15, message: '标题长度不能超过15' }],
    });

    const handleCancel = () => {
      context.emit('update:visible', false);
      // 表单重置
      resetFields();
    };

    return {
      ...toRefs(state),
      form,
      validateInfos,
      handleCancel,
      // 分类列表保存方法调用接口的方法和事件
      handleOk: () => {
        validate().then(() => {
          context.emit('fnOk', toRaw(form));
          resetFields();
        });
      },
    };
  },
};
</script>
<style lang="less" scoped>
.live-group {
  padding: 0 12px 15px 10px;
  display: flex;
  align-items: center;
  .group-input {
    flex: 1;
  }
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
}
.live-group .group-input[data-v-61219ec2] {
  flex: 1;
  padding-bottom: 15px;
}
.flex {
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
}
span.anticon.anticon-minus-circle {
  color: #5979f8 !important;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #313d5f;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
</style>
