<template>
  <div class="iss-main">
    <a-row type="flex">
      <a-col flex="336px">
        <div class="main-tree">
          <a-space :size="10">
            <a-input-search
              placeholder="请输入标签类别"
              clearable
              v-model="tagTypeSearch"
              @change="handleInputByTagType"
            >
              <!--            <template #prefix>-->
              <!--              <component :is="icons['SearchOutlined']"></component>-->
              <!--            </template>-->
            </a-input-search>
            <a-button @click="handleClickAdd"> 新增类别 </a-button>
          </a-space>
          <ul class="iss-list mt-16" ref="ulListRef">
            <li
              v-for="item in tagTypeList"
              :key="item.id"
              :class="{ selected: item.id === selectedId, 'iss-hover': true }"
              @click="handleClickClassName(item)"
            >
              <div class="list-name">
                <div class="img-box"></div>
                <a-input
                  style="height: 24px"
                  clearable
                  v-focus
                  v-if="item.edit"
                  v-model:value="editName"
                  @blur="editBlur(item)"
                  @keyup="editKeyup(item, $event)"
                ></a-input>
                <span class="flex-1" v-else>
                  {{ item.typeName }}（{{ item.tagNum || 0 }}）
                </span>
              </div>
              <div>
                <a-dropdown
                  :placement="'bottomCenter'"
                  :getPopupContainer="() => ulListRef"
                >
                  <template #overlay>
                    <a-menu>
                      <a-menu-item key="1" @click="handleClickEdit(item)">
                        <span class="menu-item">
                          <component :is="icons['EditOutlined']" />
                          编辑
                        </span>
                      </a-menu-item>
                      <a-menu-item key="2">
                        <a-popconfirm
                          title="确认删除该标签？"
                          @confirm="handleConfirm(item)"
                        >
                          <!--                      <a-tooltip title="删除" placement="right">-->
                          <span class="menu-item">
                            <component :is="icons['DeleteOutlined']" />
                            删除
                          </span>
                          <!--                      </a-tooltip>-->
                        </a-popconfirm>
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <component
                    :is="icons['MoreOutlined']"
                    class="iss-icon"
                    v-if="item.id && item.id !== '1' && item.id === selectedId"
                  ></component>
                </a-dropdown>
                <!--              <component :is="icons['MoreOutlined']"></component>-->
                <!--              <component-->
                <!--                :is="icons['EditTwoTone']"-->
                <!--                class="fs-16"-->
                <!--                @click="handleClickEdit(item)"-->
                <!--              >-->
                <!--              </component>-->
                <!--              <a-popconfirm-->
                <!--                title="确认删除该标签？"-->
                <!--                @confirm="handleConfirm(item)"-->
                <!--              >-->
                <!--                <a-tooltip placement="right">-->
                <!--                  &lt;!&ndash;                      <a class="error-color"><delete-outlined /></a>&ndash;&gt;-->
                <!--                  &lt;!&ndash;                      @click="handleClickDelete()" &ndash;&gt;-->
                <!--                  <component :is="icons['DeleteTwoTone']" class="fs-16">-->
                <!--                  </component>-->
                <!--                </a-tooltip>-->
                <!--              </a-popconfirm>-->
              </div>
            </li>
            <li v-if="isAdd">
              <div class="list-name">
                <div class="img-box"></div>
                <a-input
                  style="height: 24px"
                  clearable
                  v-focus
                  v-model:value="editName"
                  @blur="editBlur({ typeName: editName })"
                  @keyup="editKeyup({ typeName: editName }, $event)"
                ></a-input>
              </div>
            </li>
          </ul>
        </div>
      </a-col>
      <a-col flex="1" class="list-background">
        <a-spin :spinning="spinning" size="large" class="main-spin">
          <a-row class="mb-16">
            <a-col :span="18">
              <a-space>
                <a-button type="primary" @click="edit">
                  <template #icon>
                    <component :is="icons['PlusOutlined']" />
                  </template>
                  新增标签
                </a-button>
                <a-button @click="execs">
                  <template #icon>
                    <component :is="icons['SwitcherOutlined']" />
                  </template>
                  批量执行
                </a-button>
              </a-space>
            </a-col>
            <a-col :span="6">
              <a-input-search
                placeholder="请输入标签名称"
                clearable
                v-model:value="searchTagName"
                @search="onSearch"
              />
            </a-col>
          </a-row>
          <grid
            ref="gridRef"
            allow-selection
            :columns="columns"
            :code="$route.path"
            :url="url"
            :search="search"
            :scroll="{ x: 1440, y: gridHeight }"
            :key="tagTypeCode"
            :url-params="{
              tagTypeCode: tagTypeCode,
            }"
          >
            <template #autoSwitch="{ record }">
              <a-switch
                v-if="record.tagTypeCode !== 'custom'"
                :checked="record.autoSwitch"
                checked-children="启用"
                un-checked-children="禁用"
                @click="handleClickSwitch(record)"
              />
            </template>
            <template #operation="{ record }">
              <operation :options="options" :record="record" />
            </template>
            <template #executState="{ text, record }">
              <a-tag
                color="success"
                v-if="
                  text === 'ExecuteSuccessfully' &&
                  record.tagTypeCode !== 'custom'
                "
                >执行成功</a-tag
              >
              <a-tag
                color="processing"
                v-else-if="
                  text === 'Running' && record.tagTypeCode !== 'custom'
                "
              >
                执行中
              </a-tag>
              <a-tag
                color="error"
                v-else-if="
                  text === 'ExecFailure' && record.tagTypeCode !== 'custom'
                "
              >
                执行失败
              </a-tag>
              <a-tag
                color="warning"
                v-else-if="
                  text === 'ToPerform' && record.tagTypeCode !== 'custom'
                "
              >
                待执行
              </a-tag>
            </template>

            <template #tagUserCount="{ record }">
              <div class="iss-user" v-if="record.tagTypeCode !== 'custom'">
                <a-tooltip title="查看成员" placement="top"  @click="handleClickViewMember(record)">
                  <img
                    class="view-icon"
                    src="@/assets/images/clue/user.png"
                    alt=""
                  />
                </a-tooltip>
                <span style="padding-left: 5px">{{ record.tagUserCount }}</span>
              </div>
            </template>
          </grid>
        </a-spin>
      </a-col>
    </a-row>

    <a-modal
      :visible="visibleTag"
      title="携标签客户列表"
      :width="800"
      :footer="null"
      centered
      :maskClosable="false"
      @cancel="visibleTag = false"
      :afterClose="handleAfterClose"
    >
      <tag-list :tag-Code="tagCode" />
    </a-modal>
    <!--    自定义标签编辑   -->
    <!--    <a-modal-->
    <!--        :visible="visibleEdit"-->
    <!--        title="自定义标签编辑"-->
    <!--        :width="800"-->
    <!--        :footer="null"-->
    <!--        :maskClosable="false"-->
    <!--        @cancel="visibleEdit = false"-->
    <!--        :afterClose="handleAfterClose"-->
    <!--    >-->
    <!--      <a-form-->
    <!--          ref="formRef"-->
    <!--          layout="vertical"-->
    <!--          :model="tagType"-->
    <!--          :rules="tagTypeRules"-->
    <!--          :validate-trigger="['change', 'blur']"-->
    <!--      >-->
    <!--        <a-form-item name="tagName" label="标签名称">-->
    <!--          <a-input-->
    <!--              v-model:value="tagType.tagName"-->
    <!--              placeholder="请输入标签名称"-->
    <!--              :disabled="isWatch"-->
    <!--          />-->
    <!--        </a-form-item>-->
    <!--      </a-form>-->
    <!--    </a-modal>-->
  </div>
</template>

<script>
import {
  message,
  Spin,
  Space,
  Row,
  Col,
  Tree,
  Select,
  Popconfirm,
  Tooltip,
  Tag,
  Switch,
  Dropdown,
  Menu,
} from 'ant-design-vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import tagApi from '@/api/tag.js';
import { reactive, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { UserOutlined } from '@ant-design/icons-vue';
import * as icons from '@ant-design/icons-vue';
import IssFormAddPage from './addPage';
import TagList from './components/tagList';
// import Clustering from '@/api/clustering';
// import { useStore } from "vuex";

export default {
  components: {
    ASpin: Spin,
    ATag: Tag,
    ARow: Row,
    ACol: Col,
    ATree: Tree,
    ASpace: Space,
    ASelect: Select,
    APopconfirm: Popconfirm,
    ADropdown: Dropdown,
    ATooltip: Tooltip,
    Grid,
    UserOutlined,
    Operation,
    IssFormAddPage,
    TagList,
    ASwitch: Switch,
    AMenu: Menu,
    AMenuItem: Menu.Item,
  },
  setup() {
    const router = useRouter();
    const gridRef = ref();
    const ulListRef = ref();
    const state = reactive({
      spinning: false,
      visible: false,
      addActiveItem: {},
      addvisible: false,
      selectedId: '',
      manageListItem: '',
      tagTypeSearch: '',
      tagTypeCode: '',
      tagNum: '',
      id: '',
      isAdd: false,
      searchTagName: '',
      editName: '',
      tagTypeList: [],
      selectedTagType: { typeName: '全部' },
      data: [],
      value: '',
      total: '',
      tagSize: '',
      tagIds: [],
      visibleTag: false,
      tagCode: '',
    });
    const search = reactive({ tagName: '' });
    const onSearch = () => {
      search.tagName = state.searchTagName;
    };

    const handleClickViewMember = data => {
      state.visibleTag = true;
      state.tagCode = data.tagCode;
    };

    const handleAfterClose = () => {
      state.tagCode = '';
    };

    const edit = data => {
      // router.push(`/datacenter/tagmanage/${data.id || 'add'}`);
      if (data.id) {
        router.push({
          name: 'tagmanageEdit',
          params: { id: data.id, status: 'edit' },
        });
      } else {
        router.push({
          name: 'tagmanageEdit',
          params: { id: 'add', status: 'add' },
        });
      }
    };

    const deletes = ({ id }) => {
      tagApi.delete('tagmanage:delete', { id: id }).then(() => {
        message.success('操作成功');
        getTypeList();
        gridRef.value.refreshGrid();
      });
    };
    const exec = data => {
      // const hide = message.loading('执行中，请稍等', 0);
      state.spinning = true;
      state.tagIds.push(data.id);
      let tagIds = state.tagIds;
      tagApi
        .execTags('tagmanage:exec', { tagIds })
        .then(() => {
          // hide();
          message.success('操作成功');
        })
        .finally(() => {
          state.tagIds = [];
          state.spinning = false;
          gridRef.value.refreshGrid();
        });
    };

    // 标签取消执行
    // const CancelExec = data => {
    //   state.tagIds.push(data.id);
    //   let tagIds = state.tagIds;
    //
    //   Clustering.CancelExecTags('group:CancelExec', { tagIds })
    //     .then(() => {
    //       message.success('操作成功！');
    //     })
    //     .finally(() => {
    //       gridRef.value.refreshGrid();
    //     });
    // };

    const watch = data => {
      router.push({
        name: 'tagmanageEdit',
        params: { id: data.id, status: 'watch' },
      });
    };

    const execs = () => {
      let tagIds = gridRef?.value?.selectedRowKeys || [];
      if (tagIds.length > 0) {
        const hide = message.loading('执行中，请稍等', 0);
        state.spinning = true;
        tagApi
          .execTags('tagmanage:exec', { tagIds })
          .then(() => {
            hide();
            message.success('操作成功');
            gridRef.value.refreshGrid();
          })
          .finally(() => {
            state.spinning = false;
          });
      } else {
        message.info('请先选择数据');
      }
    };

    const handleSearch = val => {
      if (!val) return;
      fetch(val, d => (state.data = d));
    };

    const handleChange = val => {
      state.value = val;
      state.manageListItem = val;
      fetch(val, d => (state.data = d));
      // state.expandedKeys = getParentId(state.treeData, val);
    };

    const handleClickByTagType = () => {
      state.tagTypeCode = '';
      gridRef.value.refreshGrid();
      getTypeList();
    };
    // 查询分类
    const handleInputByTagType = value => {
      let params = { typeName: value.target.value };
      getTypeList(params);
    };

    // 标签删除的接口逻辑在这里写
    const handleConfirm = item => {
      let ids = item.id;
      if (item.tagNum !== '0') {
        return message.error('该标签类别下存在标签，删除失败');
      } else {
        tagApi.tagTypeListDelete('', { id: ids }).then(() => {
          getTypeList();
          state.selectedId = '';
          state.tagTypeCode = '';
          message.success('操作成功');
        });
      }
    };

    // 获取分类类表接口
    const getTypeList = params => {
      tagApi.getTagTypeList('', params).then(async data => {
        const size = await tagApi.getTagSize('');
        state.tagSize = size;
        state.tagTypeList = [
          {
            typeName: '全部',
            tagTypeCode: '',
            id: '',
            tagNum: size,
          },
          ...data,
        ];
      });
    };
    getTypeList();

    // const getTagNumber = () => {};
    // getTagNumber();

    // 分类标签新增
    const handleClickAdd = () => {
      state.addActiveItem = {};
      state.isAdd = true;
    };
    const handleFnOkByAdd = value => {
      let type = 'tagTypeListAdd';
      const { id } = value;
      id && (type = 'tagTypeListUpdate');
      tagApi[type](`news:${type}`, value).then(() => {
        message.success('操作成功');
        // 更新分类列表
        getTypeList();
        gridRef.value.refreshGrid();
      });
    };
    return {
      ...toRefs(state),
      handleInputByTagType,
      handleConfirm,
      handleClickByTagType,
      handleClickViewMember,
      handleAfterClose,
      onSearch,
      handleClickAdd,
      handleSearch,
      handleChange,
      gridRef,
      ulListRef,
      search,
      edit,
      exec,
      execs,
      // CancelExec,
      deletes,
      icons,
      url: tagApi.pageUrl,
      gridHeight: document.body.clientHeight - 277,
      items: [{ key: 'tagName', label: '标签名称' }],
      columns: [
        {
          dataIndex: 'tagName',
          title: '标签名称',
          width: 220,
          fixed: 'left',
          ellipsis: true,
        },
        {
          dataIndex: 'typeName',
          title: '标签类别',
          width: 160,
          ellipsis: true,
        },
        {
          key: 'tagUserCount',
          title: '携标签客户',
          slots: { customRender: 'tagUserCount' },
          width: 120,
          ellipsis: true,
        },
        {
          key: 'autoSwitch',
          title: '自动执行',
          slots: { customRender: 'autoSwitch' },
          width: 120,
          ellipsis: true,
        },

        {
          dataIndex: 'executState',
          title: '执行状态',
          slots: { customRender: 'executState' },
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'createTime',
          title: '创建时间',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'createUserName',
          title: '创建人',
          width: 120,
          ellipsis: true,
        },
        { dataIndex: 'updateTime', title: '更新时间' },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 160,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'tagmanage:add',
          fnClick: edit,
        },
        {
          type: 'exec',
          icon: 'SwitcherOutlined',
          permission: 'tagmanage:exec',
          label: '批量执行',
          fnClick: execs,
        },
      ],
      options: [
        {
          type: 'exec',
          icon: 'PlayCircleOutlined',
          permission: 'tagmanage:exec',
          label: '执行',
          show: record => {
            if (
              record.tagTypeCode === 'custom' ||
              record.executState === 'Running'
            ) {
              return false;
            } else {
              return true;
            }
          },
          fnClick: exec,
        },
        // {
        //   type: 'CancelExec',
        //   icon: 'CloseCircleOutlined',
        //   permission: 'group:CancelExec',
        //   label: '取消执行',
        //   fnClick: CancelExec,
        //   show: record => {
        //     if (record.executState === 'Running') {
        //       return true;
        //     } else {
        //       return false;
        //     }
        //   },
        // },

        {
          type: 'watch',
          icon: 'EyeOutlined',
          permission: 'tagmanage:watch',
          label: '查看',
          fnClick: watch,
          show: item => {
            if (item.tagTypeCode === 'custom') {
              return false;
            } else {
              return true;
            }
          },
        },
        {
          type: 'update',
          permission: 'tagmanage:update',
          fnClick: edit,
          show: item => {
            if (item.executState === 'Running') {
              return false;
            } else {
              return true;
            }
          },
        },
        {
          type: 'delete',
          permission: 'tagmanage:delete',
          fnClick: deletes,
          show: item => {
            if (item.executState === 'Running') {
              return false;
            } else {
              return true;
            }
          },
        },
      ],

      handleClickEdit: item => {
        // state.addvisible = true;
        item.edit = true;
        state.addActiveItem = { ...item };
      },
      editBlur: item => {
        item.edit = false;
        state.isAdd = false;
        let temp = {};
        Object.assign(temp, item);
        state.editName &&
          ((temp.typeName = state.editName),
          handleFnOkByAdd(temp),
          (state.editName = ''));
      },
      editKeyup: (item, e) => {
        if (e && e.key === 'Enter') {
          item.edit = false;
          state.isAdd = false;
          let temp = {};
          Object.assign(temp, item);
          state.editName &&
            ((temp.typeName = state.editName),
            handleFnOkByAdd(temp),
            (state.editName = ''));
        }
      },
      handleClickClassName: item => {
        state.selectedId = item.id;
        state.tagTypeCode = item.tagTypeCode;
        gridRef.value.refreshGrid();
      },
      handleFnOkByAdd,
      handleClickSwitch({ id, autoSwitch }) {
        autoSwitch = !autoSwitch;
        tagApi.updateAutoSwitch('', { id, autoSwitch }).then(res => {
          if (res) {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          }
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main {
  //height: calc(100vh - 72px);
  //overflow-y: auto;
  padding: 0;
  .list-background {
    width: calc(100% - 336px);
    & > :deep(.ant-spin-nested-loading) {
      width: 100%;
      padding: 20px;
    }
  }
}
.main-tree {
  padding: 20px;
  width: 336px;
  height: 100%;
  //border-radius: 8px;
  border-right: 1px solid #e5e6eb;
  //box-shadow: @box-shadow-base;
  //height: calc(100vh - 209px);
  //overflow-y: auto;
}
.iss-list {
  color: #48494b;
  max-height: calc(100vh - 190px);
  overflow: auto;
  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 4px;
    margin-bottom: 2px;
    transition: padding 0.3s;
    .list-name {
      display: flex;
      align-items: center;
      flex: 1;
      .img-box {
        margin-right: 8px;
        height: 16px;
        width: 16px;
        background-size: cover;
        background-image: url('../../../assets/images/clue/gruopDark.png');
      }
    }
    .iss-icon {
      color: @text-color;
    }
    &.iss-hover:hover,
    &.selected {
      color: @primary-color;
      background-color: @primary-2;
      border-radius: 5px;
      padding-left: 10px;
      .list-name .img-box {
        background-image: url('../../../assets/images/clue/groupLight.png');
      }
    }
    .menu-item {
      padding: 0 10px;
      &:hover {
        background: @primary-2;
      }
    }
  }
}

.iss-nameList {
  font-size: 20px;
}
.fs-16 {
  margin-right: 5px;
}
.iss-tabList {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
}
.iss-user {
  .view-icon {
    cursor: pointer;
    height: 16px;
    width: 16px;
    vertical-align: text-bottom;
  }
}
</style>
